import React,{lazy} from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { RouteConstants } from 'Utils/appConstants';
import { ReportAnalytics } from 'analytics/common';
import { getVerificationDetails, getRegistrationFlowDetails } from 'Utils/helpers';
const CreateAccount = lazy(() => import('Components/createAccount'));
const CriticalError = lazy(() => import('Components/criticalError'));
const EmailVerification = lazy(() => import('Components/emailVerification'));
const IdentityVerification = lazy(() => import('Components/identityVerification'));
const NameOnPolicy = lazy(() => import('Components/nameOnPolicy'));
const OneTimePin = lazy(() => import('Components/oneTimePin'));
const Start = lazy(() => import('Components/start'));
const ZipCode = lazy(() => import('Components/zipCode/organic'));
const PageUnavailable = lazy(() => import('Components/pageUnavailable'));
const SeamlessInvite = lazy(() => import('Components/zipCode/invite'));
const noNavRoutes = ['critical-error','invite','zip-code'];

class CreateRoutes extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    if (this.props.location.pathname && !(noNavRoutes.indexOf(this.props.location.pathname.replace('/','')) > -1)) {
      ReportAnalytics.navEvent()();
    }
  }
  componentWillReceiveProps(nextProps) {
    if(!nextProps || !this.props){
      return;
    }
    if (nextProps.location !== this.props.location && this.props?.location?.pathname !== '/') {
      if(!(noNavRoutes.indexOf(nextProps.location.pathname.replace('/','')) > -1)){
        const routes = [
          RouteConstants.emailVerification,
          RouteConstants.identityVerification,
          RouteConstants.createAccount,
          RouteConstants.zipCode,
          RouteConstants.nameOnPolicy,
          RouteConstants.oneTimePin
        ];
        let additionalAttributes = {};
        const verificationDetails = getVerificationDetails();
        const registrationFlowDetails = getRegistrationFlowDetails();
        if(routes.indexOf(nextProps.location.pathname) > -1) {
          additionalAttributes.email = verificationDetails.email || undefined;
          additionalAttributes.phone = verificationDetails.phone || undefined;
          additionalAttributes.registrationFlow = registrationFlowDetails.registrationFlow || undefined;
        }
        ReportAnalytics.navEvent(additionalAttributes)();
      }
      window.scrollTo(0, 1);
    }
  }

  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        !this.props.access?.accessCode ? (<Redirect to={{pathname: 'start'}} />) : (<Component {...props} />)
      }
    />
  );

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" render={() => (<Redirect to="start" />)} />
          <Route exact path={RouteConstants.start} render={(props) => <Start {...props} />} />
          <this.PrivateRoute exact path={RouteConstants.nameOnPolicy} component={NameOnPolicy} />
          <Route exact path={RouteConstants.zipCode} render={(props) => <ZipCode {...props} />} />
          <Route exact path={RouteConstants.invite} render={(props) => <SeamlessInvite {...props} />} />
          <this.PrivateRoute exact path={RouteConstants.oneTimePin} component={OneTimePin} />
          <this.PrivateRoute exact path={RouteConstants.createAccount} component={CreateAccount} />
          <this.PrivateRoute exact path={RouteConstants.emailVerification} component={EmailVerification} />
          <this.PrivateRoute exact path={RouteConstants.identityVerification} component={IdentityVerification} />
          <Route exact path={RouteConstants.criticalError} render={(props) => <CriticalError {...props} />} />
          <Route exact path={RouteConstants.notfound} render={(props) => <PageUnavailable {...props} />} />
          <Route path="*" exact={true} component={() => (<Redirect to="/notfound" />)}  />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    access: state.accessCodeReducer
  };
}

export default withRouter(connect(mapStateToProps)(CreateRoutes));
